<template>
  <v-container>
    <v-card flat>
      <!-- <v-card-title> -->
        <v-select dense outlined label="Years" class="py-0 my-0" :items="yearOptions" v-model="year" @change="fetch">
        </v-select>
      <!-- </v-card-title> -->
      <div>
        <v-card-text class="d-flex justify-center align-item ">
          <v-progress-circular v-if="api.isLoading" :size="50" color="primary" style="align-items: center;position:absolute" indeterminate>
          </v-progress-circular>
          <canvas v-show="!api.isLoading" ref="myChart">
  
          </canvas>
        </v-card-text>
      </div>
    </v-card>
  </v-container>
  </template>
<script>
import Chart from 'chart.js'

export default {
  created(){
    this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
    };
    this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {

      const ctx = this.$refs.myChart.getContext('2d')
      
      if (this.chart) {
        // Destroy the existing chart instance
        this.chart.destroy();
      }

        let month = Object.keys(resp.users);
        let users = Object.values(resp.users);
        this.users= resp.users;
        this.companies = resp.companies;
        this.videos = resp.course_classes;
        this.userWatches = resp.user_watches;
        
        this.trenData = [
          {
            x:month,
            y:Object.values(resp.users),
          }
        ];
        this.trendData={
          datasets:[users]
        }
        let config = {
          type: 'line',
          data: {
            labels: month,
            datasets: [
              {
                label: 'companies',
                backgroundColor: '#43D1EF',
                data: Object.values(resp.companies),
                borderColor:'rgb(67, 209, 135)',
                fill:false,
              },
              {
                label: 'users',
                backgroundColor: '#00C0EF',
                borderColor:'rgb(0, 192, 239)',
                data: Object.values(resp.users),
                fill:false,
              },
              {
                label:'views',
                backgroundColor:'#F9616D',
                data:Object.values(resp.user_watches),
                borderColor: "rgb(249, 97, 109)",
                fill:false,
              },
              {
                label:'videos',
                backgroundColor:'#F7BB4D',
                borderColor: "rgb(247, 187, 77)",
                data:Object.values(resp.course_classes),
                fill:false,
              },

            ]
          },
            // options: {
            //   scales: {
            //     y: {
            //       stacked: false
            //     }
            //   },
            //   plugins: {
            //     filler: {
            //       propagate: false
            //     },
            //     'samples-filler-analyser': {
            //       target: 'chart-analyser'
            //     },
            //     title:{
            //       display:true,
            //       text:'TaxPOD Performance Trendline '+this.year
            //     }
            //   },
            //   interaction: {
            //     intersect: false,
            //   },
            // },
        }
        this.chart = new Chart(ctx,config);

        this.api.isLoading = false;
        this.api.isError = false;
        this.isSucessful = true;
    }
  },
  mounted () {
    this.fetch();
    // const ctx = this.$refs.myChart.getContext('2d')
    // new Chart(ctx, {
    //   type: 'line',
    //   data: {
    //     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //     datasets: [
    //       {
    //         label: 'Data One',
    //         backgroundColor: '#f87979',
    //         data: [40, 39, 10, 40, 39, 80, 40]
    //       }
    //     ]
    //   },
    //   options: {
    //     responsive: true,
    //     maintainAspectRatio: false
    //   }
    // })
  },
  data: () => ({
    api:{
        isSucessful:false,
        isLoading :false,
        isError:false,
        error:null,
        url:null,
    },
    yearOptions:['2021','2022','2023'],
    year:'2023',
    users:null,
    companies:null,
    videos:null,
    userWatches:null,
    trenData:null,
  }),
  methods:{
    fetch(){  
      let fetchPerformanceApi = this.fetchPerformance();
      this.$api.fetch(fetchPerformanceApi);
    },
    fetchPerformance(){
      let tempApi = this.$_.clone(this.api);
      tempApi.method = "GET";
      tempApi.url = process.env.VUE_APP_SERVER_API+"/analysis/dashboard?year="+this.year;
      return tempApi;
    },
  }
}
</script>